@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);
body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

@font-face {
    font-family: Silka;
    src: url(/static/media/silka-regular-webfont.f2bcdd50.woff2);
}

@font-face {
    font-family: Galano Grotesque;
    src: url("/static/media/Galano Grotesque.af6dc7ca.otf");
}

@font-face {
    font-family: Silka Italic;
    src: url(/static/media/silka-regularitalic-webfont.bac5e481.woff2);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'Encode Sans Expanded', sans-serif; */
}

h1,
h2,
h3,
p,
a,
button {
    font-family: 'Silka', sans-serif;
    font-weight: 300;
}

.input-labeledinput {
    width: 100%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px #888;
    margin: 3px 5px;
    line-height: 140%;
}

.input-labeledinput:focus {
    outline: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px #888;
}

.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}

.checkbox + label {
    position: relative;
    padding: 0 0 0 60px;
}

.checkbox + label:before {
    content: '';
    display: inline-block;
    width: 1.2em;
    height: 1em;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: white;
}

.checkbox:checked + label:before {
    background-color: #6ac33e;
    background-image: url("http://localhost:3000/checkbox.jpg");
    background-size: 18px 16px;
}

.button-generic {
    width: 10em;
    height: 3em;
    border: none;
    border-radius: 3px;
}

.auth-button {
    margin-top: 30px;
    background-color: #3392FF;
    color: white;
}

.back-button {
    margin-top: 30px;
    background-color: white;
    color: black;
}

.button-generic:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.border-check {
    border: solid red 2px;
}

.shadow-item {
    border: none;
    border-radius: 5px;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Index Styles */

.searchBar {
    font-family: 'Silka', sans-serif;
}

.table-wrapper {
    /* margin: 0 auto; */
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
    position: relative;
    /* top: 100px; */
    /* left: vw; */
    border-radius: 10px;
}

.check-mark-input {
    width: 30px;
    height: 30px;
}

.fl-table {
    border-radius: 20px;
    font-size: 15px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: rgb(240, 240, 240);
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 15px;
    font-family: 'Silka', sans-serif;
    padding: 10px;
}

.fl-table td:hover {
    cursor: pointer;
    background: rgb(99, 99, 99);
    color: white;
    font-family: 'Silka', sans-serif;
    font-weight: 100;
}

.td-custom {
    border-right: 1px solid #f8f8f8;
    font-size: 15px;
    font-family: 'Silka', sans-serif;
    padding: 10px;
}

.td-custom:hover {
    cursor: pointer;
    background: rgb(99, 99, 99);
    color: white;
    font-family: 'Silka', sans-serif;
    font-weight: 100;
}

.fl-table thead th {
    font-family: 'Silka', sans-serif;
    font-size: 17px;
    background: #C6C6C6;
    font-weight: 800;
    padding: 13px;
    text-shadow: 1px 1px 1px rgba(61, 61, 61, 0.076);
    text-decoration: none;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    /* Firefox all */
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

.fl-table thead th:hover {
    background: #c6c6c69d !important;
    border: 1px solid white;
}

.fl-table thead th:nth-child(odd) {
    font-family: 'Silka', sans-serif;
    font-weight: 800;
    text-decoration: none;
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    /* Firefox all */
    /* IE 10+ */
    user-select: none;
    /* Likely future */
}

.fl-table thead th:nth-child(odd):hover {
    background: rgb(228, 228, 228);
    border: 1px solid white;
}

.fl-table tr:hover {
    background: rgb(189, 189, 189);
    transition: 0.2s ease-in;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

.fl-table tr:nth-child(even):hover {
    background: rgb(189, 189, 189);
    transition: 0.2s ease-in;
}

.fl-table tfoot {
    background: rgb(232, 232, 232)
}

.paginationBox {
    position: relative;
    padding: 15px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-family: 'Silka', sans-serif;
}

.paginationButtons {
    margin-left: 10px;
}

.prevButton {
    border: none;
    position: relative;
    padding: 10px;
    font-family: 'Silka', sans-serif;
    background: #3392FF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.549);
    color: #fff;
    cursor: pointer;
    margin-right: 1rem;
}

.nextButton {
    border: none;
    background: #3392FF;
    padding: 10px;
    font-family: 'Silka', sans-serif;
    color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.549);
    cursor: pointer;
}

.nextButton.disabled {
    border: none;
    background: #F9F9F9;
    padding: 10px;
    font-family: 'Silka', sans-serif;
    color: #000;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.549);
    cursor: not-allowed;
}

.prevButton.disabled {
    border: none;
    background: #F9F9F9;
    padding: 10px;
    font-family: 'Silka', sans-serif;
    color: #000;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.549);
    cursor: not-allowed;
}

.prevButton:hover,
.nextButton:hover {
    color: black;
    background: white;
    transition: 0.1s ease-in;
}

.prevButton.disabled:hover,
.nextButton.disabled:hover {
    background: #F9F9F9;
    transition: 0.1s ease-in;
}

.firstPage,
.lastPage {
    border: none;
    background: #007bff;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Silka', sans-serif;
    color: #fff;
    cursor: pointer;
    margin: 0 20px;
}

.firstPage:hover,
.lastPage:hover {
    color: black;
    background: white;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.549);
    transition: 0.1s ease-in;
}

select {
    padding: 10px;
    border: 1px solid rgb(61, 151, 211);
    border-radius: 5px;
    background: white;
    font-family: 'Silka', sanf-serif;
    margin: 0 10px;
}


/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        /* display: initial; */
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}

