@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap");

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

@font-face {
    font-family: Silka;
    src: url('./assets/fonts/silka-regular-webfont.woff2');
}

@font-face {
    font-family: Galano Grotesque;
    src: url('./assets/fonts/Galano Grotesque.otf');
}

@font-face {
    font-family: Silka Italic;
    src: url('./assets/fonts/silka-regularitalic-webfont.woff2');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: 'Encode Sans Expanded', sans-serif; */
}

h1,
h2,
h3,
p,
a,
button {
    font-family: 'Silka', sans-serif;
    font-weight: 300;
}

.input-labeledinput {
    width: 100%;
    border: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px #888;
    margin: 3px 5px;
    line-height: 140%;
}

.input-labeledinput:focus {
    outline: none;
    border-radius: 3px;
    box-shadow: 0 2px 4px #888;
}

.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}

.checkbox + label {
    position: relative;
    padding: 0 0 0 60px;
}

.checkbox + label:before {
    content: '';
    display: inline-block;
    width: 1.2em;
    height: 1em;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: white;
}

.checkbox:checked + label:before {
    background-color: #6ac33e;
    background-image: url("http://localhost:3000/checkbox.jpg");
    background-size: 18px 16px;
}

.button-generic {
    width: 10em;
    height: 3em;
    border: none;
    border-radius: 3px;
}

.auth-button {
    margin-top: 30px;
    background-color: #3392FF;
    color: white;
}

.back-button {
    margin-top: 30px;
    background-color: white;
    color: black;
}

.button-generic:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.border-check {
    border: solid red 2px;
}

.shadow-item {
    border: none;
    border-radius: 5px;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
